import axios, { AxiosResponse } from "axios";
import {
  ApiResponseFetchSamplePaperDetails,
  ApiResponseFetchSamplePapersMetadata,
  ApiResponseGetPaymentLink,
  ApiResponseSubmitAnswers,
  ApiResponseTopicWiseExamDetails,
  ApiResponseUnlockOlympiad,
  SamplePaperSubmitAnswers,
  getOrderStatusResponse,
} from "../DefinitionsInterfaces";
import api from "../axios/axiosMW";

export const fetchSamplePapers = async (): Promise<
  AxiosResponse<ApiResponseFetchSamplePapersMetadata>
> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseFetchSamplePapersMetadata> = {
    data: {
      status: "Failure",
      status_code: 400,
      data: [
        {
          samplePaperID: 1,
          paid: false,
          examTaken: true,
          dateOnWhichExamTaken: "2024-02-12T07:12:31.128659Z",
          noOfQuestionsAnswered: 20,
          totalNoOfQuestion: 30,
          time_spent: "40:00",
          percentageObtained: 50.0,
          paidForMockOlympiad: false,
        },
      ],
      message: "",
    },
    status: 400,
    statusText: "Failed",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    console.log("Requesting for sample papers Metadata");
    response = await api.post("fetch-sample-papers", {});
    console.log("Request for fetching sample papers metadata sent");
    console.log("Response: ", response);
    console.log("Response.data = ", response.data);
    console.log("Response.data.message = ", response.data.message);
    if (response.data.status_code === 200) {
      console.log("Success get Sample Papers metadata");
    } else {
      throw new Error("Failure in getting Sample Papers metadata");
    }
  } catch (error) {
    console.log("Error Getting Sample Papers Metadata:", error);
  }
  return response;
};

export const fetchSamplePaperDetails = async (
  samplePaperID: number
): Promise<AxiosResponse<ApiResponseFetchSamplePaperDetails>> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseFetchSamplePaperDetails> = {
    data: {
      status: "Failure",
      status_code: 400,
      data: [
        {
          id: 0,
          topic: "",
          subTopic: "",
          question: "",
          A: "",
          B: "",
          C: "",
          D: "",
        },
      ],
      message: "",
    },
    status: 400,
    statusText: "Failed",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    console.log("Requesting for sample paper Detail (Question paper");
    response = await api.post("fetch-sample-paper-details", {
      samplePaperID: samplePaperID,
    });
    console.log("Request for fetching QP details sent");
    console.log("Response: ", response);
    console.log("Response.data = ", response.data);
    console.log("Response.data.message = ", response.data.message);
    if (response.data.status_code === 200) {
      console.log("QP details fetched successfully ");
    } else {
      throw new Error("Failure in Fetching QP details");
    }
  } catch (error) {
    console.log("Error Getting QP details:", error);
  }
  return response;
};

export const submitSamplePaperAnswers = async (
  answerPaper: SamplePaperSubmitAnswers
): Promise<AxiosResponse<ApiResponseSubmitAnswers>> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseSubmitAnswers> = {
    data: {
      status: "Failure",
      status_code: 400,
      message: "",
      data: {
        questionsAttempted: 0,
        totalCorrectAnswerGiven: 0,
        totalQuestion: 0,
        timeTaken: "30:00",
        questions: [
          {
            id: 0,
            answerGiven: "",
            topic: "",
            subTopic: "",
            question: "",
            A: "",
            B: "",
            C: "",
            D: "",
            correctAnswer: [],
            answerSubmitted: [],
            reasoning: "",
            answeredCorrect: false,
          },
        ],
        percentage: 0,
        examsLeft: 0,
      },
    },
    status: 400,
    statusText: "Failed",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    console.log("Submitting Answer paper: Body : ", answerPaper);
    response = await api.post("submit-mock-exam", answerPaper);
    console.log("Submitting Answers");
    console.log("Response: ", response);
    console.log("Response.data = ", response.data);
    console.log("Response.data.message = ", response.data.message);
    if (response.data.status_code === 200) {
      console.log("Answers submitted successfully ");
    } else {
      throw new Error("Failure in Submitting Answers");
    }
  } catch (error) {
    console.log("Error Submitting Answers", error);
  }
  return response;
};

export const requestUnlockOlympiad = async (
  studentId: string
): Promise<AxiosResponse<ApiResponseUnlockOlympiad>> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseUnlockOlympiad> = {
    data: {
      status: "Failure",
      status_code: 400,
      message: "locked",
      data: "",
    },
    status: 400,
    statusText: "Failed",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    response = await api.post("unlock-olympiad", {
      studentID: studentId,
    });
    console.log("Response for unlocking mock olympiad: ", response);
    if (response.data.status_code === 200) {
      console.log("Unlock response success ");
    } else {
      throw new Error("Failure in unlocking mock olympiad");
    }
  } catch (error) {
    console.log("Error unlocking mock olympiad", error);
  }
  return response;
};

export const getCompletedExamResult = async (
  samplePaperID: number
): Promise<AxiosResponse<ApiResponseSubmitAnswers>> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseSubmitAnswers> = {
    data: {
      status: "Failure",
      status_code: 400,
      message: "",
      data: {
        questionsAttempted: 0,
        totalCorrectAnswerGiven: 0,
        totalQuestion: 0,
        timeTaken: "30:00",
        questions: [
          {
            id: 0,
            answerGiven: "",
            topic: "",
            subTopic: "",
            question: "",
            A: "",
            B: "",
            C: "",
            D: "",
            correctAnswer: [],
            answerSubmitted: [],
            reasoning: "",
            answeredCorrect: false,
          },
        ],
        percentage: 0,
        examsLeft: 0,
      },
    },
    status: 400,
    statusText: "Failed",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    response = await api.post("get-completed-exam-details", {
      samplePaperID: samplePaperID,
    });
    console.log("Requested for ExamResults");
    console.log("Response: ", response);
    if (response.status === 200 && response.data.status_code === 200) {
      console.log("Fetched Results Successfully ");
    } else {
      throw new Error("Failure in Fetching Results");
    }
  } catch (error) {
    console.log("Error Fetching Results", error);
  }
  return response;
};

export const getTopicWiseExamResult = async (
  samplePaperID: number
): Promise<AxiosResponse<ApiResponseTopicWiseExamDetails>> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseTopicWiseExamDetails> = {
    data: {
      status: "Failed",
      status_code: 400,
      data: {
        Money: {
          totalQuestion: 0,
          answeredRight: 0,
        },
        Banking: {
          totalQuestion: 0,
          answeredRight: 1,
        },
        "Financial planning": {
          totalQuestion: 0,
          answeredRight: 0,
        },
        Investing: {
          totalQuestion: 0,
          answeredRight: 0,
        },
        "Loans & Credits": {
          totalQuestion: 0,
          answeredRight: 0,
        },
      },
      message: "",
    },
    status: 400,
    statusText: "Failed",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    response = await api.post("get-completed-exam-topic-wise-details", {
      samplePaperID: samplePaperID,
    });
    console.log("Requested for TopicWise ExamResults");
    console.log("Response: ", response);
    if (response.status === 200 && response.data.status_code === 200) {
      console.log("Fetched TopicWise Results Successfully ");
    } else {
      throw new Error("Failure in Fetching TopicWise Results");
    }
  } catch (error) {
    console.log("Error Fetching Topicwise Results", error);
  }
  return response;
};

export const subscribeForNewMock = async (): Promise<
  AxiosResponse<ApiResponseUnlockOlympiad>
> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseUnlockOlympiad> = {
    data: {
      status: "Failure",
      status_code: 400,
      message: "unsubscribed",
      data: "",
    },
    status: 400,
    statusText: "Failed",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    response = await api.post("subscribe-student-for-new-mock-exam", {});
    console.log("Response for subscribing to new olympiad: ", response);
    if (response.data.status_code === 200) {
      console.log("Subscribe response success ");
    } else {
      throw new Error("Failure in subscribing to upcoming mock olympiad");
    }
  } catch (error) {
    console.log("Error subscribing to upcoming mock olympiad", error);
  }
  return response;
};

// Payment Related

export const getPaymentLinkForOrder = async (data: {
  orderNote: string;
}): Promise<AxiosResponse<ApiResponseGetPaymentLink>> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseGetPaymentLink> = {
    status: 400,
    statusText: "Failed",
    data: {
      status: "Failed",
      status_code: 400,
      data: {
        orderID: "",
        links: [
          {
            href: "",
            rel: "",
            method: "",
          },
        ],
      },
      message: "",
    },
  };
  try {
    const storedAccessToken = localStorage.getItem("accessToken");
    response = await axios.post(
      "https://streak-payments.el.r.appspot.com/nfo/get-payment-link-for-order",
      {
        ...data,
      },
      {
        headers: {
          // Authorization: "ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdWT2RXMWlaWElpT2lJNU5EY3lPVEk0TlRJNUlpd2lkWE5sY2tsRUlqb2lXa0ZETURrd01EVWlMQ0oxYzJWeWRIbHdaU0k2SW1OMWMzUnZiV1Z5SWl3aVpYaHdJam94TnpFeE1ERTBOalk0TENKbGJuWWlPaUp3Y205a2RXTjBhVzl1SW4wLmpOWW1tRzhMVE9KQkNoZkVMdjctbjRyek5YRXdnM3FzeEthb3Q4RTBwSmJoVkRFb0lzejh4S3RERWtUZXZEZWJDa21SQjlJM28waTYwb1cxX3B1cnFNaVpoSXVNZ0tHTzVrVDNFVzlPT1V3Vkc3cTc4UFNTbzNocjlGRlJpVFZuYmdkQjNWTlItZnE2TGhicGlVR1pLdF9tbFJyY2ZoWXlwTzNyeVUtQmxWUQ==",
          Authorization: `${storedAccessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response = ", response);

    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.status_code === 200 &&
      response.data.data
    ) {
      console.log("Successful Response", response);
    } else {
      throw "Invalid response Get Payment Link";
    }
  } catch (error) {
    console.log("API failed with error : ", error);
  }
  return response;
};

export function getOrderStatus(
  orderID: string
): Promise<getOrderStatusResponse> {
  const storedAccessToken = localStorage.getItem("accessToken");
  return axios.post(
    "https://streak-payments.el.r.appspot.com/nfo/verify-payment-for-order",
    {
      orderID: orderID,
    },
    {
      headers: {
        // Authorization: "ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdWT2RXMWlaWElpT2lJNU5EY3lPVEk0TlRJNUlpd2lkWE5sY2tsRUlqb2lXa0ZETURrd01EVWlMQ0oxYzJWeWRIbHdaU0k2SW1OMWMzUnZiV1Z5SWl3aVpYaHdJam94TnpFeE1ERTBOalk0TENKbGJuWWlPaUp3Y205a2RXTjBhVzl1SW4wLmpOWW1tRzhMVE9KQkNoZkVMdjctbjRyek5YRXdnM3FzeEthb3Q4RTBwSmJoVkRFb0lzejh4S3RERWtUZXZEZWJDa21SQjlJM28waTYwb1cxX3B1cnFNaVpoSXVNZ0tHTzVrVDNFVzlPT1V3Vkc3cTc4UFNTbzNocjlGRlJpVFZuYmdkQjNWTlItZnE2TGhicGlVR1pLdF9tbFJyY2ZoWXlwTzNyeVUtQmxWUQ==",
        Authorization: `${storedAccessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
}

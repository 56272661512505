import { FunctionComponent } from "react";
import { certificateItems } from "../../../../Utils/api/auth";

interface ParticipationCertificateProps {
  id: string;
  data: certificateItems;
  individualRegistration: boolean;
}

const OutstandingCertificate: FunctionComponent<
  ParticipationCertificateProps
> = ({ id, data, individualRegistration }) => {
  const { examDate } = data?.data;

  const getExamDateFromUnix = (dateValue: Date) => {
    let date = new Date(dateValue);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract the day, month, and year from the Date object
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    // Format the date as "DD Month YYYY"
    let formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  };

  const calculateFontSize = (name: string) => {
    const baseSize = 50;
    if (!name) return baseSize;

    if (name.length > 20) {
      return baseSize - Math.min(Math.floor((name.length - 20) * 2), 20);
    }
    return baseSize;
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <div>
        <div
          id={id}
          style={{
            width: "847px",
            position: "relative",
            backgroundColor: "#f1fafe",
            border: "20px solid #DC7A20",
            boxSizing: "border-box",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "43px 21px 25px 20px",

            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "center",
            fontSize: "18px",
            color: "#000",
            fontFamily: "Cardo",
          }}
        >
          <div
            style={{
              width: "364px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              gap: "34px",
              maxWidth: "100%",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "0px 20px 0px 21px",
              }}
            >
              <img
                style={{
                  height: "70.5px",
                  width: "159.9px",
                  position: "relative",
                }}
                loading="lazy"
                src={require("./cert-assets/vector.png")}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "0px",
                maxWidth: "100%",
              }}
            >
              <h2
                style={{
                  margin: "0",
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "30px",
                  lineHeight: "92%",
                  fontWeight: "700",
                  fontFamily: "inherit",
                  color: "#DC7A20",
                }}
              >
                Certificate of
                <br />
                Outstanding Performance
              </h2>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  padding: "0px 22px 0px 23px",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: "2px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      padding: "0px 40px",
                    }}
                  >
                    <img
                      style={{
                        height: "3px",
                        width: "239px",
                        position: "relative",
                      }}
                      loading="lazy"
                      alt=""
                      src={require("./cert-assets/OutstandingCertsLine.png")}
                    />
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 34px",
                      color: "#183041",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: "0px 0px 6.7px",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "12px 0px 0px 0px",
                        }}
                      >
                        <b style={{ position: "relative", lineHeight: "29px" }}>
                          This is to certify that
                        </b>
                      </div>
                      <h1
                        style={{
                          margin: "0",
                          position: "relative",
                          fontSize: `${calculateFontSize(data?.data.name)}px`,
                          lineHeight: "60px",
                          fontWeight: "700",
                          fontFamily: "inherit",
                          alignSelf: "center",
                          width: "500px",
                        }}
                      >
                        {`${data?.data.name}`}
                      </h1>
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "5px",
                      }}
                    >
                      <i
                        style={{
                          position: "relative",
                          lineHeight: "139%",
                          display: "inline-block",
                          fontStyle: "italic",
                          minWidth: "62px",
                          flexDirection: "row",
                        }}
                      >{`Of grade ${data?.data?.grade} of ${data?.data.school}`}</i>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "139%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "stretch",
                    }}
                  >{`achieved rank ${
                    data.data.scoreAnalysis.batchRank
                  } in the ${
                    individualRegistration ? "Level-I" : "intra school level"
                  } of the`}</div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  padding: "0px 0px 0px 0px",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      height: "25px",
                      position: "relative",
                      lineHeight: "139%",
                      display: "flex",
                      width: "370px",
                    }}
                  >{`National Finance Olympiad held on ${getExamDateFromUnix(
                    data?.data.examDate
                  )}`}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "364px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px 6px 0px 0px",
              boxSizing: "border-box",
              maxWidth: "100%",
              fontSize: "13.8px",
            }}
          >
            <img
              alt="CashFree Signature"
              style={{
                objectFit: "contain",
                height: "40px",

                width: "100px",
              }}
              src={require("./cert-assets/CashFreeFounderOrange.png")}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "0px 28px",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    lineHeight: "19px",
                    fontWeight: "700",
                    display: "inline-block",
                    minWidth: "71px",
                  }}
                >
                  Reeju datta
                </b>
              </div>
              <div
                style={{
                  position: "relative",
                  lineHeight: "19px",
                  display: "inline-block",
                  minWidth: "128px",
                }}
              >
                Co-founder, Cashfree
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutstandingCertificate;

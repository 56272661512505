// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/Assets/images/group-11917.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footers {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    display: flex;
    width: 100%;
    height: 100px;
    color: #8f8f8f;
    opacity: 0.88;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(241, 250, 254, 1);
    flex-direction: row;
}

`, "",{"version":3,"sources":["webpack://./src/layouts/Footer.css"],"names":[],"mappings":"AAAA;IACI,yDAAyD;IACzD,aAAa;IACb,WAAW;IACX,aAAa;IACb,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,wCAAwC;IACxC,mBAAmB;AACvB","sourcesContent":[".footers {\n    background-image: url(/src/Assets/images/group-11917.png);\n    display: flex;\n    width: 100%;\n    height: 100px;\n    color: #8f8f8f;\n    opacity: 0.88;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    background-color: rgba(241, 250, 254, 1);\n    flex-direction: row;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

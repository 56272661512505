import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import "./HTMLViewer.css";
import { useLocation, useNavigate } from "react-router";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
import {
  certificateItems,
  FeedbackData,
  getCertificateItems,
  getFeedbackQuestions,
  submitFeedbackAnswers,
  getCertificateItemsNationals,
} from "../../../../Utils/api/auth";
import { AuthContext } from "../../../../App";

import { LoadingButton } from "@mui/lab";
import OutstandingCertificate from "./OutstandingCertificate";
import { backArrowImage } from "../../../../Assets/images";
import Modal from "../../../../Components/modal/Modal";
import Carousel from "../../../../Components/carousel/Carousel";
import NationalExcellenceCertificate from "./Certificates/NationalExcellenceCertificate";
import NationalOutstandingCertificate from "./Certificates/NationalOutstandingCertificate";
import NationalParticipationCertificate from "./Certificates/NationalParticipationCertificate";
import NationalZonalCertificate from "./Certificates/NationalZonalCertificate";

interface ParticipationCertificateProps {
  id: string;
  data: certificateItems;
  individualRegistration: boolean;
}

const ParticipationCertificate: FunctionComponent<
  ParticipationCertificateProps
> = ({ id, data, individualRegistration }) => {
  const { examDate } = data?.data;

  const getExamDateFromUnix = (dateValue: Date) => {
    let date = new Date(dateValue);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract the day, month, and year from the Date object
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    // Format the date as "DD Month YYYY"
    let formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  };

  const calculateFontSize = (name: string) => {
    const baseSize = 50;
    if (!name) return baseSize;

    if (name.length > 20) {
      return baseSize - Math.min(Math.floor((name.length - 20) * 2), 20);
    }
    return baseSize;
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div>
        <div
          id={id}
          style={{
            width: "847px",
            position: "relative",
            backgroundColor: "#f1fafe",
            border: "20px solid #2c6ab1",
            boxSizing: "border-box",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "43px 21px 25px 20px",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "center",
            fontSize: "18px",
            color: "#000",
            fontFamily: "Cardo",
          }}
        >
          <div
            style={{
              width: "364px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              gap: "24px",
              maxWidth: "100%",
              marginBottom: "30px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "0px 20px 0px 21px",
              }}
            >
              <img
                alt="NFO Logo"
                style={{
                  height: "70.5px",
                  width: "159.9px",
                  position: "relative",
                }}
                loading="lazy"
                src={require("./cert-assets/vector.png")}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "7px",
                maxWidth: "100%",
              }}
            >
              <h2
                style={{
                  margin: "0",
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "30px",
                  lineHeight: "92%",
                  fontWeight: "700",
                  fontFamily: "inherit",
                  color: "#2c6ab1",
                }}
              >
                Certificate of Participation
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  padding: "0 22px",
                  width: "100%",
                  maxWidth: "700px",
                  margin: "0 auto",
                }}
              >
                {/* Certificate Text */}
                <b style={{ lineHeight: "25px" }}>This is to certify that</b>

                {/* Student Name */}
                <h1
                  style={{
                    margin: "0",
                    fontSize: `${calculateFontSize(data?.data.name)}px`,
                    lineHeight: "60px",
                    fontWeight: "700",
                    fontFamily: "inherit",
                    width: "700px",
                    textAlign: "center",
                  }}
                >
                  {data?.data.name}
                </h1>

                {/* School Info */}
                <i
                  style={{
                    lineHeight: "120%",
                    fontStyle: "italic",
                    textAlign: "center",

                    width: "700px",
                  }}
                >
                  {`Of grade ${data?.data?.grade} from ${data?.data.school}`}
                </i>

                {/* Rank Info */}
                <div
                  style={{
                    lineHeight: "90%",
                    textAlign: "center",
                    width: "700px",
                  }}
                >
                  {`participated in the ${
                    individualRegistration ? "Level-I" : "intra school level"
                  } of the`}
                </div>

                {/* Exam Date */}
                <div
                  style={{
                    lineHeight: "90%",
                    textAlign: "center",
                    width: "700px",
                  }}
                >
                  {`National Finance Olympiad held on ${getExamDateFromUnix(
                    data?.data.examDate
                  )}`}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "364px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 6px 0px 0px",
              boxSizing: "border-box",
              maxWidth: "100%",
              fontSize: "13.8px",
              marginTop: "20px",
            }}
          >
            <img
              alt="cashfree signature"
              style={{
                objectFit: "contain",
                height: "50px",

                width: "100px",
              }}
              src={require("./cert-assets/Vector 2734.png")}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "0 28px",
                  fontWeight: "700",
                  lineHeight: "19px",
                  minWidth: "71px",
                }}
              >
                Reeju Datta
              </div>
              <div
                style={{
                  lineHeight: "19px",
                  minWidth: "128px",
                }}
              >
                Co-founder, Cashfree
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HTMLViewer = () => {
  const {
    auth: { student_username, report_locked, individualRegistration },
  } = useContext(AuthContext);

  const [data, setData] = useState<certificateItems>();
  const [loader, setLoader] = useState(false);
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [isModalOpen, setModalOpen] = useState(true);
  const location = useLocation();
  const { examType, showZonalRank } = location.state;
  console.log({ examType, showZonalRank });

  const getFeedbackQuestionsApiCall = async () => {
    setLoader(true);
    getFeedbackQuestions()
      .then((response) => {
        //@ts-ignore
        if (response.data.status === "Success") {
          //@ts-ignore
          setFeedbackQuestions(response.data.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (examType !== "national" && report_locked) {
      console.log(report_locked === true);
      getFeedbackQuestionsApiCall();
    } else {
      getCertificateData();
    }
  }, [report_locked]);

  const getCertificateData = async () => {
    setLoader(true);
    const isNational = examType === "national";
    console.log(isNational);
    const apiCall = isNational
      ? getCertificateItemsNationals
      : getCertificateItems;

    apiCall(student_username).then((response) => {
      //@ts-ignore
      if (response.data.status === "Success") {
        //@ts-ignore
        setData(response.data);
      }
      setLoader(false);
    });
  };

  const submitFeedbackQuestions = async (values: FeedbackData) => {
    setLoader(true);
    submitFeedbackAnswers(values)
      .then((response) => {
        //@ts-ignore
        if (response.data.data.status === "Success") {
          setModalOpen(false);
          setLoader(false);
        } else {
          setModalOpen(false);
          setLoader(false);
        }
      })
      .then(() => {
        window.location.reload();
      });
  };

  const openPDF = () => {
    setLoader(true);
    generatePDF(() => document.getElementById("wrapper"), options).then(() => {
      setLoader(false);
    });
  };

  const options: Options = {
    filename: data?.data?.studentUsername,
    // default is `save`
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      margin: Margin.SMALL,
      format: "A4",
      orientation: "landscape",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // customize any value passed to the jsPDF instance and html2canvas
    // function
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
        orientation: "landscape",
      },

      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
        scale: 4,
      },
    },
  };
  const navigation = useNavigate();

  const determineAward = (batchRank: number, zonalRank: number) => {
    if (showZonalRank) {
      if (zonalRank >= 1 && zonalRank <= 10) {
        return "National Zonal Certificate";
      }
    }
    if (examType === "national") {
      if (batchRank === 1 || batchRank === 2 || batchRank === 3) {
        return "Certificate Of Outstanding Performance";
      }
      if (batchRank >= 4 && batchRank <= 25) {
        return "Certificate Of National Excellence";
      }
      if (batchRank > 25) {
        return "Certificate Of Participation";
      }
      return "Outstanding Certificate";
    } else {
      if (data?.data.awardWon === "Participation Certificate") {
        return "Participation Certificate";
      } else {
        return "Outstanding Certificate";
      }
    }
  };

  const batchRank = data?.data?.scoreAnalysis?.batchRank ?? 0;
  const zonalRank = data?.data?.zonalRank ?? 0;
  const awardWon = determineAward(batchRank, zonalRank);

  return (
    <div style={{ backgroundColor: "#F1faFe" }}>
      {report_locked === true && feedbackQuestions.length > 1 && (
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <h2 style={{ color: "rgba(44, 106, 177, 1)" }}>User Feedback</h2>
          <Carousel
            submitButtonText="View Certificate"
            questions={feedbackQuestions}
            onSubmit={(values) => {
              submitFeedbackQuestions(values);
            }}
          />
        </Modal>
      )}
      {data && (
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={backArrowImage}
            alt=""
            onClick={() => {
              navigation("/dashboard");
            }}
            style={{
              cursor: "pointer",
              marginBottom: "5px",
              position: "absolute",
              left: "4%",
              top: "5.5%",
            }}
          />
          <LoadingButton
            variant="contained"
            loading={loader}
            onClick={openPDF}
            style={{
              marginTop: 50,
              marginBottom: 50,
              height: 40,
            }}
          >
            Download Certificate
          </LoadingButton>
          {(() => {
            console.log(awardWon);
            switch (awardWon) {
              case "Certificate Of National Excellence":
                return (
                  <NationalExcellenceCertificate
                    id={"wrapper"}
                    data={data}
                    individualRegistration={individualRegistration}
                  />
                );
              case "Certificate Of Outstanding Performance":
                return (
                  <NationalOutstandingCertificate
                    id={"wrapper"}
                    data={data}
                    individualRegistration={individualRegistration}
                  />
                );
              case "Certificate Of Participation":
                return (
                  <NationalParticipationCertificate
                    id={"wrapper"}
                    data={data}
                    individualRegistration={individualRegistration}
                  />
                );
              case "National Zonal Certificate":
                return (
                  <NationalZonalCertificate
                    id={"wrapper"}
                    data={data}
                    individualRegistration={individualRegistration}
                  />
                );
              case "Outstanding Certificate":
                return (
                  <OutstandingCertificate
                    id={"wrapper"}
                    data={data}
                    individualRegistration={individualRegistration}
                  />
                );
              case "Participation Certificate":
                return (
                  <ParticipationCertificate
                    id={"wrapper"}
                    data={data}
                    individualRegistration={individualRegistration}
                  />
                );

              default:
                return null;
            }
          })()}
        </div>
      )}
    </div>
  );
};

export default HTMLViewer;

import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "./ExamCard.css";
import { dateFormat, timeFormat } from "./constants";
import { AuthContext } from "../../../../App";
import {
  NationalsGetStudent,
  getCertificateItemsNationals,
} from "../../../../Utils/api/auth";

interface ExamDetailsType {
  level1: {
    examStatus: number;
    examDay: string;
    examStartTime: string;
    examEndTime: string;
  };
  nationals: {
    examStatus: number;
    examDay: string;
    examStartTime: string;
    examEndTime: string;
  };
}

const EXAM_DETAILS_INIT = {
  level1: {
    examStatus: 0,
    examDay: "",
    examStartTime: "",
    examEndTime: "",
  },
  nationals: {
    examStatus: 0,
    examDay: "",
    examStartTime: "",
    examEndTime: "",
  },
};

const ExamCard = () => {
  const navigate = useNavigate();
  const {
    auth: {
      report_generated,
      examDate,
      examLink,
      individualRegistration,
      qualifiedForNationals,
      student_username,
    },
  } = useContext(AuthContext);

  const [selectedExamState, setSelectedExamState] = useState<
    keyof ExamDetailsType
  >(qualifiedForNationals ? "nationals" : "level1");
  const slideVariants = {
    level1: { x: "0%" },
    nationals: { x: "100%" },
  };

  const [examDetails, setExamDetails] =
    useState<ExamDetailsType>(EXAM_DETAILS_INIT);

  const [examStatus, setExamStatus] = useState(0);
  const [examDay, setExamDay] = useState("");
  const [examStartTime, setExamStartTime] = useState("");
  const [examEndTime, setExamEndTime] = useState("");
  const [examRulesLink, setExamRulesLink] = useState("");
  const [nationalsExamLink, setNationalsExamLink] = useState("");
  const [isZonalCertificateVisible, setIsZonalCertificateVisible] =
    useState(false);
  const [zone, setZone] = useState("");
  const [allIndiaRank, setAllIndiaRank] = useState("");
  const [zonalRank, setZonalRank] = useState(0);
  const [showZonalData, setShowZonalData] = useState(false);

  const cardHeaders = [
    "Exam not Scheduled",
    "Your Olympiad is scheduled on",
    "Your Olympiad starts on",
    "Thank you for participating",
    "Thank you for participating",
  ];

  const cardBody = [
    "Your Exam date has not been assigned yet",
    "",
    "",
    selectedExamState === "nationals"
      ? "Your NFO Level-II results are available for download. Click to download"
      : "Your NFO Level-I results are available for download. Click to download",
    individualRegistration
      ? qualifiedForNationals
        ? "Your NFO Level-II results will be announced on 22nd February 2025"
        : "Your NFO Level-I results will be announced soon"
      : qualifiedForNationals
      ? "Your NFO Level-II results will be announced on 22nd February 2025"
      : "Your NFO Level-I results will be announced soon",
  ];

  const cardBG = [
    "rgba(255, 171, 46, 0.2)",
    "#bbd2f552",
    "rgba(255, 171, 46, 0.2)",
    "rgba(188, 255, 46, 0.2)",
    "rgba(188, 255, 46, 0.2)",
    "rgba(188, 255, 46, 0.2)",
  ];

  const cardColor = [
    "rgba(170, 58, 21, 1)",
    "#2c6ab1",
    "rgba(170, 58, 21, 1)",
    "rgba(15, 102, 8, 1)",
    "rgba(15, 102, 8, 1)",
    "rgba(15, 102, 8, 1)",
  ];

  const button1Txt = [
    "Contact Support",
    "View Exam Rules",
    "Start Olympiad",
    "Certificate",
    "",
  ];

  const handleContactSupportClick = () => {
    window.open(
      "mailto:email@example.com?subject=Subject&body=Body%20goes%20here"
    );
  };

  const handleExamRulesClick = () => {
    navigate("/pdf-preview", {
      state: {
        type: "examrules",
        buttonText: "Download",
        link:
          selectedExamState === "nationals"
            ? examRulesLink
            : "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      },
    });
  };

  const handleStartOlympiadClick = () => {
    if (selectedExamState === "nationals") {
      window.open(nationalsExamLink);
    } else {
      window.open(examLink);
    }
  };

  const handleCertificateViewer = () => {
    navigate("/certificate-viewer", {
      state: {
        examType: selectedExamState === "nationals" ? "national" : "examrules",
        link: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      },
    });
  };

  const handleSecondButtonClick = () => {
    navigate("/report-viewer", {
      state: {
        examType: selectedExamState === "nationals" ? "national" : "examrules",
      },
    });
  };

  const button1Links = [
    handleContactSupportClick,
    handleExamRulesClick,
    handleStartOlympiadClick,
    handleCertificateViewer,
    handleExamRulesClick,
  ];

  const setData = <
    levelType extends keyof ExamDetailsType,
    fieldType extends keyof ExamDetailsType[levelType]
  >(
    level: levelType,
    field: fieldType,
    data: any
  ) => {
    setExamDetails((prev) => ({
      ...prev,
      [level]: {
        ...prev[level],
        [field]: data,
      },
    }));
  };

  const getExamTimingNationals = async () => {
    if (qualifiedForNationals) {
      try {
        const response = await NationalsGetStudent(student_username);
        setExamRulesLink(response.data.data.examRules);
        setNationalsExamLink(response.data.data.examLink);
        return {
          examDate: response.data.data.examDate,
          examLink: response.data.data.examLink,
        };
      } catch (error) {
        console.log(error);
      }
    } else {
      return null;
    }
  };

  console.log("examDetails: ", examStatus);

  const getExamStatus = async (level: keyof ExamDetailsType) => {
    if (level === "nationals") {
      Promise.all([
        getExamTimingNationals(),
        getCertificateItemsNationals(student_username),
      ])
        .then(([examTimingNationals, certificateItems]) => {
          if (examTimingNationals) {
            const dt =
              (new Date(examTimingNationals.examDate).getTime() -
                new Date().getTime()) /
              (1000 * 60 * 60);

            console.log("exam date: ", dt);

            let examStatus;
            if (certificateItems) {
              const {
                report_generated,
                disqualified,
                showZoneData,
                showZonalCertificate,
                zonalRank,
                zone,
                batchRank,
                //@ts-ignore
              } = certificateItems.data.data;
              console.log("report_generated: ", report_generated);

              if (report_generated) {
                examStatus = 3; // Show Certificate and Report button
              } else if (!report_generated && !disqualified) {
                examStatus = 1; // User did not appear for the exam
              } else if (!report_generated && disqualified) {
                examStatus = 5; // User disqualified due to bad practices
              }
              console.log("showZonalCertificate: ", showZonalCertificate);

              setIsZonalCertificateVisible(showZonalCertificate);
              setShowZonalData(showZoneData);
              setZone(zone);
              setZonalRank(zonalRank);
              setAllIndiaRank(batchRank);

              if (showZoneData) {
                console.log(`Zone: ${zone}, Zonal Rank: ${zonalRank}`);
                if (showZonalCertificate && zonalRank <= 10) {
                  console.log("Zonal Certificate will be shown");
                }
              }
            } else {
              examStatus = dt > 36 ? 1 : dt < 36 && dt > -2 ? 2 : 0;
            }

            setData(level, "examStatus", examStatus);
          }
        })
        .catch((error) => {
          console.error(
            "Error fetching exam timing or certificate items:",
            error
          );
          setData(level, "examStatus", 0); // Default to 0 if there's an error
        });
    } else {
      // If not nationals, calculate directly from examDate
      const dt =
        (new Date(examDate).getTime() - new Date().getTime()) /
        (1000 * 60 * 60);

      const examStatus =
        dt > 36
          ? 1
          : dt < 36 && dt > -2
          ? 2
          : dt < -2 && report_generated
          ? 3
          : dt < -2 && !report_generated
          ? 4
          : 0;

      console.log("exam date level 1: ", dt, examStatus, examDetails);

      setData(level, "examStatus", examStatus);
    }
  };

  const getExamTiming = async (level: keyof ExamDetailsType) => {
    console.log("here", level);

    // Using the .then method for getExamTimingNationals()
    let examStart: Date;
    if (level === "nationals") {
      await getExamTimingNationals()
        .then((response) => {
          if (response) {
            examStart = new Date(response.examDate);
            console.log("exam start: ", examStart);
            if (examStart) {
              const examEnd = new Date(
                new Date(examStart).setHours(examStart.getHours() + 1)
              );

              setData(
                "nationals",
                "examDay",
                new Date(examStart).toLocaleDateString("en-US", dateFormat)
              );
              setData(
                "nationals",
                "examStartTime",
                examStart.toLocaleTimeString("en-US", timeFormat)
              );
              setData(
                "nationals",
                "examEndTime",
                examEnd.toLocaleTimeString("en-US", timeFormat)
              );
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching exam timing:", error);
          return; // Handle the error appropriately
        });
    } else {
      examStart = new Date(examDate);
      if (examStart) {
        const examEnd = new Date(
          new Date(examStart).setHours(examStart.getHours() + 1)
        );

        setData(
          "level1",
          "examDay",
          new Date(examDate).toLocaleDateString("en-US", dateFormat)
        );
        setData(
          "level1",
          "examStartTime",
          examStart.toLocaleTimeString("en-US", timeFormat)
        );
        setData(
          "level1",
          "examEndTime",
          examEnd.toLocaleTimeString("en-US", timeFormat)
        );
      }
    }
  };

  useEffect(() => {
    if (qualifiedForNationals) {
      getExamStatus("nationals").then(() => {
        getExamTiming("nationals");
      });
    }
    getExamStatus("level1");
    getExamTiming("level1");
  }, [examDate]);

  useEffect(() => {
    setExamStatus(examDetails[selectedExamState].examStatus);
    setExamDay(examDetails[selectedExamState].examDay);
    setExamStartTime(examDetails[selectedExamState].examStartTime);
    setExamEndTime(examDetails[selectedExamState].examEndTime);
  }, [selectedExamState, examDetails]);

  const schoolIDs = [
    "BAW", // Meridian School for Boys and Girls, Banjara Hills
    "DXW", // Peepal Prodigy India
    "EXC", // Swami Vivekanand Academy, CBSE School
    "FRN", // Chinmaya Vidyalaya, Rajendra Nagar
    "HBG", // Avasara Academy, Bavdhan
    "LWA", // Baselios Public School
    "NJW", // The Academic City School & Emerald International School
    "OVJ", // Loreto Convent School
    "TGB", // India International School
    "UCO", // SND Public School
    "XCY", // Avasara Academy
    "UTB", // Countryside International School
    "IFR", // Cambridge World School
    "UNV", // Chinmaya International Residential School,
    "YYL", //National Public School, ITPL
    "OVJ", //Loreto Convent School
  ];

  // Function to extract school ID from student username and check validity
  function isSchoolID(student_username: string) {
    // Extract the first three characters as the school ID
    const schoolID = student_username.substring(0, 3);
    // Check if the extracted school ID is in the list
    return schoolIDs.includes(schoolID);
  }

  return (
    <div
      className="exam-card"
      style={{
        color: cardColor[examStatus],
        backgroundColor: cardBG[examStatus],
      }}
    >
      {qualifiedForNationals && (
        <div
          className="exam-card-switch"
          style={{
            backgroundColor: cardColor[examStatus],
          }}
        >
          <motion.div
            className="exam-card-switch-active"
            initial={false}
            animate={selectedExamState}
            variants={slideVariants}
            transition={{
              type: "spring",
              duration: 0.5,
            }}
          />
          <div
            className="exam-card-option"
            onClick={() => setSelectedExamState("level1")}
            style={{
              color:
                selectedExamState === "nationals"
                  ? "#FFF"
                  : cardColor[examStatus],
            }}
          >
            Level-I
          </div>
          <div
            style={{
              color:
                selectedExamState === "level1" ? "#FFF" : cardColor[examStatus],
            }}
            className="exam-card-option"
            onClick={() => setSelectedExamState("nationals")}
          >
            Level-II
          </div>
        </div>
      )}
      <div className="exam-card-content">
        <p className="exam-card-head">{cardHeaders[examStatus]}</p>
        {examStatus === 1 || examStatus === 2 ? (
          <p className="exam-card-body">
            {examDay} <br /> {examStartTime} to {examEndTime} IST
          </p>
        ) : (
          <p className="exam-card-body">{cardBody[examStatus]}</p>
        )}
        {(examStatus === 1 || examStatus === 2) &&
          (isSchoolID(student_username) ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "18px",
                }}
              >
                Exam Location :
                <div
                  style={{
                    backgroundColor: "#fff",
                    color: cardColor[examStatus],
                    padding: "2px 6px",
                    borderRadius: "4px",
                    marginLeft: "4px",
                    fontSize: "18px",
                  }}
                >
                  <strong>School</strong>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                flexDirection: "row",
                marginTop: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "18px",
                  justifyContent: "space-between",
                  marginTop: "8px",
                  marginBottom: "8px",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "2px 6px",
                }}
              >
                Exam Location :
                <div
                  style={{
                    color: cardColor[examStatus],

                    marginLeft: "10px",
                    fontSize: "18px",
                  }}
                >
                  <strong>Home</strong>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "18px",
                  justifyContent: "space-between",
                  marginTop: "8px",
                  marginBottom: "8px",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "2px 6px",
                }}
              >
                Roll Number :
                <div
                  style={{
                    color: cardColor[examStatus],
                    marginLeft: "10px",
                    fontSize: "18px",
                  }}
                >
                  <strong>{student_username}</strong>
                </div>
              </div>
            </div>
          ))}

        {examStatus === 3 &&
          selectedExamState === "nationals" &&
          qualifiedForNationals &&
          showZonalData && (
            <div>
              <div
                style={{
                  flexDirection: "row",
                  marginTop: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: "18px",
                    justifyContent: "space-between",
                    marginTop: "8px",
                    marginBottom: "8px",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    padding: "2px 6px",
                  }}
                >
                  All India Rank :
                  <div
                    style={{
                      color: cardColor[examStatus],
                      marginLeft: "10px",
                      fontSize: "18px",
                    }}
                  >
                    <strong>{allIndiaRank}</strong>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: "18px",
                    justifyContent: "space-between",
                    marginTop: "8px",
                    marginBottom: "8px",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    padding: "2px 6px",
                  }}
                >
                  Zonal Rank :
                  <div
                    style={{
                      color: cardColor[examStatus],
                      marginLeft: "10px",
                      fontSize: "18px",
                    }}
                  >
                    <strong>{zonalRank}</strong>
                  </div>
                </div>

                {examStatus === 3 && selectedExamState === "nationals" && (
                  <div
                    className="card-button"
                    style={{
                      backgroundColor: cardColor[examStatus],
                      cursor: "pointer",
                    }}
                    onClick={handleSecondButtonClick}
                  >
                    Report Card
                  </div>
                )}
              </div>
            </div>
          )}

        <div
          className="card-buttons"
          style={{
            color: cardBG[examStatus],
          }}
        >
          {button1Txt[examStatus] && (
            <div
              className="card-button"
              style={{
                backgroundColor: cardColor[examStatus],
              }}
              onClick={button1Links[examStatus]}
            >
              {button1Txt[examStatus]}
            </div>
          )}
          {selectedExamState === "nationals" &&
            examStatus === 3 &&
            isZonalCertificateVisible && (
              <div
                className="card-button"
                style={{
                  backgroundColor: cardColor[examStatus],
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/certificate-viewer", {
                    state: {
                      type: "national",
                      examType: "national",
                      showZonalRank: true,
                      link: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
                    },
                  });
                }}
              >
                Zonal Certificate
              </div>
            )}
          {examStatus === 3 && selectedExamState !== "nationals" && (
            <div
              className="card-button"
              style={{
                backgroundColor: cardColor[examStatus],
                cursor: "pointer",
              }}
              onClick={handleSecondButtonClick}
            >
              Report Card
            </div>
          )}

          {examStatus === 3 &&
            selectedExamState === "nationals" &&
            !isZonalCertificateVisible &&
            !showZonalData && (
              <div
                className="card-button"
                style={{
                  backgroundColor: cardColor[examStatus],
                  cursor: "pointer",
                }}
                onClick={handleSecondButtonClick}
              >
                Report Card
              </div>
            )}

          {examStatus !== 4 &&
            examStatus !== 3 &&
            examStatus !== 1 &&
            examStatus !== 5 && (
              <div
                className="card-button"
                style={{
                  color: cardColor[examStatus],
                  fontSize: "1.1rem",
                  cursor: "pointer",
                }}
                onClick={handleExamRulesClick}
              >
                <u>Exam Rules</u>
              </div>
            )}

          {examStatus === 5 && (
            <div
              style={{
                color: cardColor[examStatus],
                fontSize: "1.2rem",
                cursor: "pointer",
                maxWidth: "350px",
              }}
            >
              We regret to inform you that your exam results are currently
              withheld . This decision is the outcome of a thorough evaluation
              process. If you require further clarification, please contact us.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExamCard;

import React, { useContext, useEffect, useState } from "react";
import "../DashboardComponentsStyles.css";
import {
  mockQuizImage,
  OlympiadPreviewImage,
  ProductInfoIcon,
} from "../../../../Assets/images";
import { fetchSamplePapers } from "../../../../Utils/MockQuizAPIs/MockQuizAPIs";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../App";
import { CircularProgress } from "@mui/material";
import PreviewModal from "../Bookshelf/PreviewModal";
import OlympiadLottie from "../../../../Assets/lotties/OlympiadLottie.json";
import Lottie from "lottie-react";
import { LogEventBody, logEvent } from "../../../../Utils/api/auth";

interface MockQuizCardProps {
  cbHandleMockClick: () => void;
}

const ITEM_ID = 2;

const MockQuizCard = (props: MockQuizCardProps) => {
  const navigate = useNavigate();
  const [showInfoModal, setShowInfoModal] = useState(false);

  const {
    auth: {
      created_at,
      school_id,
      student_username,
      name,
      phone,
      email,
      grade,
      batch_id,
      invite_sent,
      report_generated,
      exam_notification_sent,
      report_id,
      paid_for_mock_olympiad,
      notify_for_new_mock_olympiads,
      school,
      examDate,
      olympiad_year,
    },
  } = useContext(AuthContext);

  const [numExamsTaken, setNumExamsTaken] = useState<number | null>(null);
  const [purchasedMock, setPurchasedMock] = useState<boolean | null>(null);
  const [totalExamPapers, setTotalExamPapers] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  const handleShowInfoModal = async () => {
    setShowInfoModal(true);
    //uncomment if required

    // const logBody: LogEventBody = {
    //   studentUserID: student_username,
    //   eventName: 'view_item_info',
    //   productIDs: [ITEM_ID]
    // }
    // logEvent(logBody)
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchSamplePapers();
      if (response.status === 200 && response.data && response.data.data) {
        const QPArray = response.data.data;
        let examsTaken = 0;
        for (let i = 0; i < QPArray.length; i++) {
          if (QPArray[i].examTaken) {
            examsTaken++;
          }
        }

        setNumExamsTaken(examsTaken);
        setTotalExamPapers(QPArray.length);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Sample Papers Metadata:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "259.95px",
        width: "30%",
        zIndex: 100,
        background: "rgba(251,251,251,0)",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className="nfo-portal-v2-mock-quiz-card">
      {olympiad_year === 2025 && (
        <div
          style={{
            position: "absolute",

            backgroundColor: "#DCECF2",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
            borderRadius: "16px",
          }}
        >
          <p>NFO Practice Test is under maintenance</p>
        </div>
      )}
      {numExamsTaken !== null &&
        numExamsTaken > 0 &&
        !paid_for_mock_olympiad && (
          <Lottie
            animationData={OlympiadLottie}
            style={{ position: "absolute", top: 0, height: 60, width: 120 }}
            loop={true}
          />
        )}
      <img
        className="mobile-nfo-portal-v2-image"
        alt="Image"
        src={mockQuizImage}
      />
      <img
        style={{
          height: "65%",
          width: "55%",
          objectFit: "contain",
          position: "absolute",
          bottom: 0,
          right: 0,
        }}
        alt="Image"
        src={mockQuizImage}
      />
      <div className="nfo-portal-v2-content-3">
        <div>
          <div>
            <div style={{ display: "flex" }}>
              <div className="nfo-portal-v2-text-wrapper-9">
                NFO Practice Test
              </div>
              {!paid_for_mock_olympiad && (
                <img
                  onClick={handleShowInfoModal}
                  src={ProductInfoIcon}
                  style={{
                    height: "20px",
                    width: "20px",
                    marginTop: 10,
                    cursor: "pointer",
                    marginLeft: "20px",
                  }}
                />
              )}
            </div>
            <div style={{ marginTop: 40, width: "50%" }}>
              <p className="nfo-portal-v2-para">
                Measure your readiness before the NFO Practice Test with
                real-time practice exams.
              </p>
            </div>
          </div>
        </div>
        {/* {!numExamsTaken && (
          <button
            className="nfo-portal-v2-button-3"
            onClick={props.cbHandleMockClick}
          >
            <div className="nfo-portal-v2-text-wrapper-10">
              Begin 1st free practice exam
            </div>
          </button>
        )} */}
        {!paid_for_mock_olympiad && (
          <button
            className="nfo-portal-v2-button-3"
            onClick={() => {
              const token = localStorage.getItem("accessToken");
              openInSameTab(
                // `https://main.d35wd7ni4is1r.amplifyapp.com/CheckoutPage/?itemId=${51}&token=${token}`
                // `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${2}&token=${token}`
                `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${ITEM_ID}&token=${token}&userName=${student_username}`
              );
            }}
          >
            <div className="nfo-portal-v2-text-wrapper-10">
              Buy NFO Practice Test
            </div>
          </button>
        )}
        {/* {numExamsTaken !== null &&
          numExamsTaken > 0 &&
          !paid_for_mock_olympiad && (
            <button
              className="nfo-portal-v2-button-3 retry-button"
              onClick={props.cbHandleMockClick}
            >
              <div className="nfo-portal-v2-text-wrapper-10 retry-text">
                Retry question paper
              </div>
            </button>
          )} */}
        {paid_for_mock_olympiad && (
          <div className="view-remain-wrap">
            <button
              className="nfo-portal-v2-button-3 view-button"
              onClick={props.cbHandleMockClick}
            >
              <div className="nfo-portal-v2-text-wrapper-10 view-text">
                Begin Practice Test
              </div>
            </button>
            <div className="sample-done">
              {numExamsTaken}/{totalExamPapers} Papers Solved
            </div>
          </div>
        )}
      </div>
      <PreviewModal
        itemName={"NFO Practice Test"}
        buttonText="Buy 12 Practice Tests Now"
        onPress={() => {
          const token = localStorage.getItem("accessToken");
          // openInSameTab(
          //   // ` https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${2}&token=${token}`
          //   `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${2}&token=${token}&userName=${student_username}`
          // );
          props.cbHandleMockClick();
        }}
        imgSrc={OlympiadPreviewImage}
        basePrice="499"
        openModal={showInfoModal}
        cbHandleCloseModal={() => {
          setShowInfoModal(false);
        }}
        currentDescription={
          "NFO Practice Test offers an online environment mirroring the actual Olympiad. With the NFO Practice Test, you can simulate exam conditions with 12 authentic question papers and timed assessments! Understand your weaknesses with detailed performance reports to aid preparation."
        }
        sellingPrice={"299"}
      />
    </div>
  );
};

export default MockQuizCard;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nsh-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    justify-self: center;
    position: relative;
}

.nsh-header-summary {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.nsh-back-button {
    border-radius: 50%;
    width: fit-content;
    padding: 0;
    border: none;
}

.nsh-header-text-wrapper {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36.6px;
    margin-top: -1px;
    margin-left: 1vw;
    opacity: 0.9;
    position: relative;
    /* width: 514px; */
}

.nsh-header-NFO-logo {
    width: 150px;
    object-fit: cover;
    position: relative;
}

@media only screen and (max-width: 768px) {
    .nsh-back-button-image {
        width: 26px;
    }
    
    .nsh-header-text-wrapper {
        color: #023b8f;
        font-family: "Cardo", Helvetica;
        font-size: 20px;
        font-weight: 400;
    }
    
    .nsh-header-NFO-logo {
        display: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/NFOSessionHistory/NFOSessionHistoryComponents/NFOSessionHistoryHeader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,+BAA+B;IAC/B,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,cAAc;QACd,+BAA+B;QAC/B,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".nsh-header {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    padding: 10px;\n    justify-self: center;\n    position: relative;\n}\n\n.nsh-header-summary {\n    align-items: center;\n    align-self: center;\n    display: flex;\n    flex: 0 0 auto;\n    flex-direction: row;\n    justify-content: center;\n    position: relative;\n}\n\n.nsh-back-button {\n    border-radius: 50%;\n    width: fit-content;\n    padding: 0;\n    border: none;\n}\n\n.nsh-header-text-wrapper {\n    color: #023b8f;\n    font-family: \"Cardo\", Helvetica;\n    font-size: 1.8rem;\n    font-weight: 400;\n    letter-spacing: 0;\n    line-height: 36.6px;\n    margin-top: -1px;\n    margin-left: 1vw;\n    opacity: 0.9;\n    position: relative;\n    /* width: 514px; */\n}\n\n.nsh-header-NFO-logo {\n    width: 150px;\n    object-fit: cover;\n    position: relative;\n}\n\n@media only screen and (max-width: 768px) {\n    .nsh-back-button-image {\n        width: 26px;\n    }\n    \n    .nsh-header-text-wrapper {\n        color: #023b8f;\n        font-family: \"Cardo\", Helvetica;\n        font-size: 20px;\n        font-weight: 400;\n    }\n    \n    .nsh-header-NFO-logo {\n        display: none !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useContext } from "react";
import { AuthContext } from "../../../../App";
import Cookies from "js-cookie";
import ExamCard from "../ExamCard/ExamCard";
import { mailIcon } from "../../../../Assets/svgs";
import "./UserDetails.css";
import NationalsBadge from "../NationalsBadge/NationalsBadge";

const UserDetails = ({ copyReferral }: { copyReferral: () => void }) => {
  const {
    auth: {
      student_username,
      name,
      grade,
      school,
      showMockOlympiad,
      referralActive,
      qualifiedForNationals,
    },
  } = useContext(AuthContext);
  return (
    <div className="studentid-wrap">
      <div className="studentid">
        <b className="name">{name}</b>
        {qualifiedForNationals && (
          <NationalsBadge
            txt="Qualified for Level-II Nationals"
            height="30px"
          />
        )}
        <div className="line" />
        <div className="grade">{grade}th Grade</div>
        <div className="line" />
        <div className="grade">
          <div className="grade">{school} </div>
        </div>
        <div className="line" />
        <div className="grade">
          <div className="grade">Roll No.- {student_username} </div>
        </div>
        <div className="line" />
        <div
          onClick={() => {
            Cookies.remove("accessToken");
            sessionStorage.clear();
            localStorage.clear();
            window.location.reload();
          }}
          className="sign-out"
        >
          Sign-Out
        </div>
        {/* {referralActive && !qualifiedForNationals && (
          <button
            className="refer-btn"
            type="button"
            onClick={() => copyReferral()}
          >
            Refer now
          </button>
        )} */}
      </div>
      <div className="laptop-exam-cards">
        <ExamCard />
      </div>

      <button className="supportbutton">
        <img className="vector-icon" alt="" src={mailIcon} />
        <a
          className="support-text"
          href="mailto:support@nationalfinanceolympiad.com"
        >
          Contact NFO Support
        </a>
      </button>
    </div>
  );
};

export default UserDetails;

import { useNavigate } from "react-router";
import "./NFORecordedSession.css";
import { AuthContext } from "../../../../App";
import { useContext, useEffect, useState } from "react";
import { getStoreItems, StoreItems } from "../../../../Utils/api/auth";

const NFORecordedSessionCard = () => {
  const navigate = useNavigate();

  const {
    auth: { grade, olympiad_year },
  } = useContext(AuthContext);

  const imageUrl =
    "https://storage.googleapis.com/nfo-public-asset/Mask%20group.png";
  const [locked, setLocked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  function getGradeId(grade: number) {
    switch (true) {
      case grade >= 6 && grade <= 8:
        return 32;
      case grade >= 9 && grade <= 10:
        return 34;
      case grade >= 11 && grade <= 12:
        return 35;
      default:
        throw new Error("Invalid grade");
    }
  }

  const handleFetchShelfItems = () => {
    getStoreItems()
      .then((res) => {
        setIsLoading(true);
        const gradeId = getGradeId(grade);

        //@ts-ignore
        if (res?.data?.status === "Success") {
          //@ts-ignore
          const items = res.data.data.items;
          const recordedSessions = items.filter(
            (e: StoreItems) => e.id === gradeId
          );

          if (recordedSessions.length > 0) {
            const locked = recordedSessions[0].locked;

            setLocked(locked);
          } else {
            setIsLoading(true);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch store items:", error);

        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleFetchShelfItems();
  }, []);

  const isVisible = olympiad_year === 2025;

  return isVisible ? (
    <></>
  ) : (
    <div className="ns-card">
      {/* <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
        }}
      >
        <div className="ns-badge">
          <div className="ns-badge-dot animated-opacity"></div>
          Level-II Special
        </div>
      </div> */}

      <div className="ns-content">
        <img className="ns-img" alt="Img" src={imageUrl} />
        <div className="ns-heading">NFO Recorded Sessions</div>
        <div className="ns-subheading">
          Access recorded classes taught by expert tutors and gear up to excel
          in the upcoming Level-II Olympiad with confidence.
        </div>

        <div
          style={{
            marginTop: "15px",
            gap: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: locked
                ? "rgba(46, 107, 178,1)"
                : "rgba(88, 120, 157, 0.15)",
            }}
            className="ns-button-recorded"
            onClick={() =>
              grade >= 6 && grade <= 12 ? navigate("/nfo-recorded") : () => {}
            }
          >
            <div
              style={{
                color: locked ? "#FFF" : "rgba(46, 107, 178,1)",
              }}
              className="ns-button-txt-recorded"
            >
              {grade >= 6 && grade <= 12
                ? locked
                  ? "Buy"
                  : "View"
                : "Coming Soon"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFORecordedSessionCard;

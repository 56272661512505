import React, { FunctionComponent } from "react";
import { certificateItems } from "../../../../../Utils/api/auth";

interface NationalExcellenceCertificateProps {
  id: string;
  data: certificateItems;
  individualRegistration: boolean;
}

const NationalExcellenceCertificate: FunctionComponent<
  NationalExcellenceCertificateProps
> = ({ id, data }) => {
  const calculateFontSize = (name: string) => {
    const baseSize = 50;
    if (!name) return baseSize;

    if (name.length > 15) {
      return baseSize - Math.min(Math.floor((name.length - 15) * 1), 10);
    }
    return baseSize;
  };
  const theme = {
    baseColor: "#307E0B",
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <div
          id={id}
          style={{
            width: "847px",
            position: "relative",
            backgroundColor: "#FFF",
            boxSizing: "border-box",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "42px 21px 45px 20px",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "center",
            fontSize: "18px",
            color: "#000",
            fontFamily: "Cardo",
            backgroundImage: `url(${require("../cert-assets/NationalsExcellenceCertificateBorder.png")})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "100% 100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              gap: "24px",
              maxWidth: "100%",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "0px 20px 0px 21px",
              }}
            >
              <img
                alt="NFO Logo"
                style={{
                  height: "87.5px",
                  position: "relative",
                }}
                loading="lazy"
                src={require("../cert-assets/vector.png")}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "7px",
                maxWidth: "100%",
              }}
            >
              <div
                style={{
                  margin: "0",
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "48px",
                  lineHeight: "92%",
                  fontWeight: "700",
                  fontFamily: "inherit",
                  color: theme.baseColor,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Certificate of<br></br>National Excellence
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  padding: "0 22px",
                  width: "100%",
                  maxWidth: "700px",
                  margin: "0 auto",
                }}
              >
                {/* Certificate Text */}
                <b style={{ lineHeight: "25px" }}>This is to certify that</b>

                {/* Student Name */}
                <h1
                  style={{
                    margin: "0",
                    fontSize: `${calculateFontSize(data?.data.name)}px`,
                    lineHeight: "60px",
                    fontWeight: "700",
                    fontFamily: "inherit",
                    width: "700px",
                    textAlign: "center",
                  }}
                >
                  {data?.data.name}
                </h1>

                {/* School Info */}
                <i
                  style={{
                    lineHeight: "120%",
                    fontStyle: "italic",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {`Of grade ${data?.data?.grade} from ${data?.data.school}`}
                </i>

                {/* Rank Info */}
                <div
                  style={{
                    lineHeight: "90%",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {`achieved All India Rank ${data?.data.scoreAnalysis.batchRank} in the National level (Level II) of the`}
                </div>

                {/* Exam Date */}
                <div
                  style={{
                    lineHeight: "90%",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {`National Finance Olympiad held between 1st - 2nd February 2025`}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 6px 0px 0px",
                boxSizing: "border-box",

                fontSize: "13.8px",

                marginLeft: "76px",
              }}
            >
              <img
                alt="CashFree Signature"
                style={{
                  objectFit: "contain",
                  height: "50px",
                  width: "60px",
                }}
                src={require("../cert-assets/CashfreeFounderGreen.png")}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "0 28px",
                    fontWeight: "700",
                    lineHeight: "19px",
                    minWidth: "71px",
                  }}
                >
                  Reeju Datta
                </div>
                <div
                  style={{
                    lineHeight: "19px",
                    minWidth: "128px",
                  }}
                >
                  Co-founder, Cashfree
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 6px 0px 0px",
                boxSizing: "border-box",
                fontSize: "13.8px",
                marginTop: "0px",
                marginRight: "76px",
              }}
            >
              <img
                alt="CashFree Signature"
                style={{
                  objectFit: "contain",
                  height: "50px",
                  width: "100px",
                }}
                src={require("../cert-assets/StreakCoFounderSignatureGreen.png")}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "0 28px",
                    fontWeight: "700",
                    lineHeight: "19px",
                    minWidth: "71px",
                  }}
                >
                  Shiv Bidani
                </div>
                <div
                  style={{
                    lineHeight: "19px",
                    minWidth: "128px",
                  }}
                >
                  Co-founder, Streak
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NationalExcellenceCertificate;

import arrowLeftWhite from "./arrow-left-white.svg";
import chevronRight from "./chevron-right.svg";
import arrowLeftCircle from "./arrow-left-circle.svg";
import arrowRightCircle from "./arrow-right-circle.svg";
import downloadIcon from "./downloadIcon.svg";
import mailIcon from "./mail.svg";
import playButton from "./play-button.svg";
import downArrow from "./down-arrow.svg";
import sound from "./sound.svg";
import fullscreen from "./fullscreen.svg";
import pause from "./pause.svg";
import muteIcon from "./mute.svg";
import forward10 from "./forward10.svg";
import reverse10 from "./reverse10.svg";
export {
  arrowLeftWhite,
  chevronRight,
  arrowLeftCircle,
  arrowRightCircle,
  downloadIcon,
  mailIcon,
  playButton,
  downArrow,
  sound,
  fullscreen,
  pause,
  muteIcon,
  forward10,
  reverse10,
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-container {
  width: 100%;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(220, 252, 212, 1);
  color: rgba(23, 98, 4, 1);
  font-weight: bold;
  box-shadow: 0px 0px 1px 1px rgba(220, 252, 212, 1);
}

.badge-img {
  width: 40px;
  height: 40px;
  object-fit: contain;

}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/DashboardComponents/NationalsBadge/NationalsBadge.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,wCAAwC;EACxC,yBAAyB;EACzB,iBAAiB;EACjB,kDAAkD;AACpD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;;AAErB","sourcesContent":[".badge-container {\n  width: 100%;\n  border-radius: 10px;\n  padding: 5px 10px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: rgba(220, 252, 212, 1);\n  color: rgba(23, 98, 4, 1);\n  font-weight: bold;\n  box-shadow: 0px 0px 1px 1px rgba(220, 252, 212, 1);\n}\n\n.badge-img {\n  width: 40px;\n  height: 40px;\n  object-fit: contain;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 0px 40px 1px rgba(44, 106, 177, 0.3);
    max-width: 500px;
    width: 100%;
    position: relative;
    border-width: 0.75px;
    border-color: rgba(0, 0, 0, 0.1);
  }

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

.fade-out {
  animation: fadeOut 0.3s ease-in-out;
}
  
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(1); }
    to { opacity: 1; transform: scale(1); }
  }
  
  @keyframes fadeOut {
    from { opacity: 1; transform: scale(1); }
    to { opacity: 0; transform: scale(1); }
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
  }

  @media screen and (max-width:768px) {

    .modal-content{
        margin-left: 5%;
        margin-right: 5%;
        border-radius: 10px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,oDAAoD;IACpD,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,oBAAoB;IACpB,gCAAgC;EAClC;;AAEF;EACE,kCAAkC;AACpC;;AAEA;EACE,mCAAmC;AACrC;;EAEE;IACE,OAAO,UAAU,EAAE,mBAAmB,EAAE;IACxC,KAAK,UAAU,EAAE,mBAAmB,EAAE;EACxC;;EAEA;IACE,OAAO,UAAU,EAAE,mBAAmB,EAAE;IACxC,KAAK,UAAU,EAAE,mBAAmB,EAAE;EACxC;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;EACjB;;EAEA;;IAEE;QACI,eAAe;QACf,gBAAgB;QAChB,mBAAmB;IACvB;EACF","sourcesContent":[".modal-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.1);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .modal-content {\n    background-color: white;\n    padding: 20px;\n    border-radius: 16px;\n    box-shadow: 0px 0px 40px 1px rgba(44, 106, 177, 0.3);\n    max-width: 500px;\n    width: 100%;\n    position: relative;\n    border-width: 0.75px;\n    border-color: rgba(0, 0, 0, 0.1);\n  }\n\n.fade-in {\n  animation: fadeIn 0.3s ease-in-out;\n}\n\n.fade-out {\n  animation: fadeOut 0.3s ease-in-out;\n}\n  \n  @keyframes fadeIn {\n    from { opacity: 0; transform: scale(1); }\n    to { opacity: 1; transform: scale(1); }\n  }\n  \n  @keyframes fadeOut {\n    from { opacity: 1; transform: scale(1); }\n    to { opacity: 0; transform: scale(1); }\n  }\n  \n  .close-button {\n    position: absolute;\n    top: 10px;\n    right: 15px;\n    font-size: 1.5rem;\n    font-weight: bold;\n    cursor: pointer;\n  }\n\n  @media screen and (max-width:768px) {\n\n    .modal-content{\n        margin-left: 5%;\n        margin-right: 5%;\n        border-radius: 10px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nfo-portal-v2-frame-5{
    position: absolute;
    background-color: #dcecf2;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    border-radius: 16px;
    padding: 10px;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .nfo-portal-v2-frame-5{
        position: absolute;
        background-color: #dcecf2;
        height: 100%;
        width: 100%;
        display: flex;
        max-width: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 10%;
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/DashboardComponents/Bookshelf/Bookshelf.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI;QACI,kBAAkB;QAClB,yBAAyB;QACzB,YAAY;QACZ,WAAW;QACX,aAAa;QACb,eAAe;QACf,SAAS;QACT,2BAA2B;QAC3B,YAAY;QACZ,kBAAkB;IACtB;AACJ","sourcesContent":[".nfo-portal-v2-frame-5{\n    position: absolute;\n    background-color: #dcecf2;\n    height: 100%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 15;\n    border-radius: 16px;\n    padding: 10px;\n    text-align: center;\n}\n\n@media screen and (min-width: 768px) {\n    .nfo-portal-v2-frame-5{\n        position: absolute;\n        background-color: #dcecf2;\n        height: 100%;\n        width: 100%;\n        display: flex;\n        max-width: 100%;\n        left: 50%;\n        transform: translateX(-50%);\n        padding: 10%;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dc-error-container {
    position: fixed;
    z-index: 20;
    width: 100%;
    top: 90px;
}

.dc-error {
    width: fit-content;
    margin: 0 auto;
    background-color: rgba(224, 239, 247);
    box-shadow: 3px 5px 10px rgba(128, 128, 128, 0.20);
    border-radius: 10px;
    padding: 10px 20px;
}

.text-error {
    color: red;
}

@media only screen and (max-width: 768px) {
    .dc-error-container{
        top: 65px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/NFOSession/NFOSessinComponents/ErrorModal.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,WAAW;IACX,WAAW;IACX,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,qCAAqC;IACrC,kDAAkD;IAClD,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,SAAS;IACb;AACJ","sourcesContent":["\n.dc-error-container {\n    position: fixed;\n    z-index: 20;\n    width: 100%;\n    top: 90px;\n}\n\n.dc-error {\n    width: fit-content;\n    margin: 0 auto;\n    background-color: rgba(224, 239, 247);\n    box-shadow: 3px 5px 10px rgba(128, 128, 128, 0.20);\n    border-radius: 10px;\n    padding: 10px 20px;\n}\n\n.text-error {\n    color: red;\n}\n\n@media only screen and (max-width: 768px) {\n    .dc-error-container{\n        top: 65px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

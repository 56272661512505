// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container{
    width:auto;
    height:60vh;
    overflow:hidden;
    position:relative;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video-container iframe {
    pointer-events: none;
  }
  .video-container iframe{
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    height: calc(100% + 120px);
  }
  .video-foreground{
  pointer-events: none;
}

@media (max-width: 768px) {
  .video-container {
    height: 60vh;
  }

  .video-container iframe{
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: calc(100% + 120px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/DashboardComponents/VideoCard/VideoCard.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;EACd;EACA;IACE,oBAAoB;EACtB;EACA;IACE,kBAAkB;IAClB,UAAU;IACV,OAAO;IACP,WAAW;IACX,0BAA0B;EAC5B;EACA;EACA,oBAAoB;AACtB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,OAAO;IACP,WAAW;IACX,0BAA0B;EAC5B;AACF","sourcesContent":[".video-container{\n    width:auto;\n    height:60vh;\n    overflow:hidden;\n    position:relative;\n  }\n  .video-container iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  }\n  .video-container iframe {\n    pointer-events: none;\n  }\n  .video-container iframe{\n    position: absolute;\n    top: -70px;\n    left: 0;\n    width: 100%;\n    height: calc(100% + 120px);\n  }\n  .video-foreground{\n  pointer-events: none;\n}\n\n@media (max-width: 768px) {\n  .video-container {\n    height: 60vh;\n  }\n\n  .video-container iframe{\n    position: absolute;\n    top: -60px;\n    left: 0;\n    width: 100%;\n    height: calc(100% + 120px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

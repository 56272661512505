import background from "./bg.png";
import NFO from "./nfo.svg";
import TitleLine from "./titleline.svg";
import group11917 from "./group-11917.png";
import backArrowImage from "./backArrowImage.svg";
import cancelPolicyImage from "./cancelPolicyImage.png";
import clock from "./clock.svg";
import shelfTitleRect from "./rectangle-3701.svg";
import shelfDepthRect from "./rectangle-3697.svg";
import shelfFrontRect from "./vector-1.svg";
import mockQuizImage from "./mockQuizImage.png";
import userProfileVector from "./userProfileVector.svg";
import arrowLeftWhite from "./arrow-left-white.svg";
import backArrowWithoutFill from "./backArrowWithoutFill.svg";
import refreshCcw from "./refresh-cw.svg";
import ProductInfoIcon from "./ProductInfoIcon.png";
import OlympiadPreviewImage from "./OlympiadPreviewImage.png";
import NFOLogoGreen from "./NFO_green.svg";
import BundleBanner from "./BundleBanner.png";
import BundleBannerMobile from "./BundleBannerMobile.png";
import ComplimentaryBadge from "./ComplimentaryBadge.png";
import replayIcon from "./replayIcon.svg";
import closeIcon from "./closeIcon.svg";
import nationalsBundleBanner from "./nationalsBundleBanner.png";
import nationalsBundleBannerMobile from "./nationalsBundleBannerMobile.png";
import StreakPortalBanner from "./StreakPortalBanner.png";
import StreakPortalMobileBanner from "./StreakPortalMobileBanner.png";

export {
  background,
  NFO,
  NFOLogoGreen,
  TitleLine,
  group11917,
  backArrowImage,
  cancelPolicyImage,
  clock,
  shelfTitleRect,
  shelfDepthRect,
  shelfFrontRect,
  mockQuizImage,
  userProfileVector,
  arrowLeftWhite,
  backArrowWithoutFill,
  refreshCcw,
  ProductInfoIcon,
  OlympiadPreviewImage,
  BundleBanner,
  BundleBannerMobile,
  ComplimentaryBadge,
  replayIcon,
  closeIcon,
  nationalsBundleBanner,
  nationalsBundleBannerMobile,
  StreakPortalBanner,
  StreakPortalMobileBanner,
};
